<template>
<div>
  <v-card>
  <v-data-table
    :headers="headers"
    :items="budgetList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
      flat dense>
      <h2 class="mei-title">Submitted Budget</h2>
      </v-toolbar>
      <v-toolbar
        flat
      >
        <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Search"
        color="green darken-2"
        single-line
        hide-details
      ></v-text-field>
        <v-spacer></v-spacer>
        <v-autocomplete
            v-model="QuarterID"
            item-text="description"
            item-value="id"
            :items="quarters"
            hide-details
            color="header"
            label="Quarter"
            style="max-width: 180px"
            class="mr-2"
          >
          </v-autocomplete>
        <vue-json-to-csv 
          :json-data="itemListForCSV"
          :labels="{ 
            BudgetCode: { title: 'Budget Code' },
            FundCode: { title: 'Fund Code' },
            OfficeDescription: { title: 'Office' },
            DeliveryUnitDescription: { title: 'Delivery Unit' },
            Amount: { title: 'Amount' },
            PerBudgetRecord: { title: 'PerBudgetRecord' },
            Remarks: { title: 'Remarks' },
            Status: { title: 'Status' },
            DateCreated: { title: 'Date Created' },
            }"
          csv-title="List of Budgets">
            <v-btn
              outlined
              color="green darken-2"
              dark
              :loading="loadingCSV"
            >
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              CSV
            </v-btn>
          </vue-json-to-csv>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.PerBudgetRecord`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.PerBudgetRecord || 0) }}</span>
      </template>
      <template v-slot:[`item.BudgetUtilized`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.AmountNDBM_PO) + parseFloat(item.AmountNDBM_OBL) + parseFloat(item.AmountDBM_PR) + parseFloat(item.AmountLIB_OBL)) }}</span>
      </template>
      <template v-slot:[`item.Status`]="{ item }">
          <v-chip color="green darken-2" dark small v-if="item.SubmittedBy > 0">Submitted</v-chip>
          <v-chip color="amber darken-2" dark small v-else>Pending</v-chip>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn color="blue darken-2" class="white--text mr-2" outlined x-small @click="viewItem(item)">
          view
          </v-btn>
          <v-btn color="red darken-2" class="white--text" outlined x-small @click="print(item)">
          Print M&E
          </v-btn>
        </span>
      </template>
  </v-data-table>
   </v-card>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    props: {
      fiscalYear: null,
    },
    data: () => ({
      searchText: "",
      searchTimeout : null,
      amountErr: null,
      amountErr2: null,
      editItemId: null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      menu1: false,
      formData: {
         BudgetID: null,
         PerBudgetRecord: null,
         Remarks: null,
      },
      addBudgetDialog: false,
      budgetList: [],
      fundList: [],
      office: [],
      deliveryunit: [],
      specificfundsourceList: [],
      purposeList: [],
      allotmentList: [],
      users: [],
      headers: [
        { text: 'Quarter', value: 'Quarter', sortable: false, align: 'center', class: "green darken-2 white--text text-caption font-weight-bold" },
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'Amount', value: 'Amount', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Per Budget Record', value: 'PerBudgetRecord', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Amount (Utilized)', value: 'BudgetUtilized', align: 'end', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Remarks', value: 'Remarks', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" }
      ],
      itemListForCSV: [],
      loadingCSV: true,
      QuarterID: 0,
      OfficeID: 0,
      DeliveryUnitID: 0,
      PurposeID: 0,
      SpecificFundSourceID: 0,
      UserID: 0,
      officeList : [],
      deliveryUnit: [],
      purpose: [],
      specificFundSource: [],
      DateRange: null,
      menu2: false,
      budgetsForRevision: [],
      savings: 0,
      budget: [],
      budgetDetails: {},
      Status: 0,
      statuses: [
        {id: 0, description: 'All'},
        {id: 1, description: 'Pending'},
        {id: 2, description: 'Submitted'},
      ],
      quarters: [
        { id: 0, description: 'All' },
        { id: 1, description: 'First' },
        { id: 2, description: 'Second' },
        { id: 3, description: 'Third' },
        { id: 4, description: 'Fourth' },
      ]
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
    fiscalYear: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    QuarterID: {
        handler () {
         this.initialize();
        },
      },
    },
    created () {
      // this.initialize();
    },
    methods: {
      print(data) {
        window.open(this.apiUrl + "/mei/report/" + data.MEIID);
      },
      cancel() {
        this.editItemId = null;
        this.transferItemId = null;
        this.$refs.formRef.reset();
        this.addBudgetDialog = false;
      },
      initialize () {
        if(this.fiscalYear != null){
        this.loading = true;
        this.loadingCSV = true;
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
          data.append('searchText', this.searchText);
          data.append('FiscalYearID', this.fiscalYear);
          data.append("QuarterID", this.QuarterID);
          data.append("UserID", this.$store.state.user.UserID);
              this.axiosCall(
              '/getAllBudgetMAndEByUserSubmitted',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false;
            this.loadingCSV = false;
              this.budgetList = res.data.data.result;
              this.itemListForCSV = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
            this.getBudgetList();
          })
        }
      },
    //   getBudgetTypeAndOffice() {
    //   this.axiosCall(
    //           '/getOfficeAndOthersForCreateUpdate',
    //           'GET'
    //       )
    //       .then((res) => {
    //           this.office = res.data.data.office;
    //           this.fundList = res.data.data.fund;
    //           this.specificfundsourceList = res.data.data.specificfundsource;
    //           this.purposeList = res.data.data.purpose;
    //           this.allotmentList = res.data.data.allotmenttype;
    //           this.deliveryunit = res.data.data.deliveryunit;
    //           this.users = [{UserID: 0, name: 'All'}, ...res.data.data.users];
    //       })
		// },
    changeBudgetMEI(){
      let data = this.formData.BudgetID;
      if(data){
      let details = this.budget.find(b => b.BudgetID === data);
        this.budgetDetails = details;
      }
      else{
        this.budgetDetails = {};
      }
    },
    getBudgetList() {
      this.budget = [];
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear);
      this.axiosCall(
              '/getBudgetMAndEList',
              'POST',
              data
          )
          .then((res) => {
              this.budget = res.data.data;
          })
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
          this.isLoading = true;
          let data = new FormData();
          data.append('BudgetID', this.formData.BudgetID);
          data.append('PerBudgetRecord', this.formData.PerBudgetRecord);
          data.append('Remarks', this.formData.Remarks);
                      this.axiosCall(
                    '/mei/add',
                    'POST',
                    data
                )
                .then((res) => {
                    this.isLoading = false;
                    if(res.data.status) {
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'success';
                    this.fadeAwayMessage.message = 'Budget Successfully Added!';
                    this.fadeAwayMessage.header = 'System Message';
                    this.$refs.formRef.reset();
                    this.addBudgetDialog = false;
                      this.initialize();
                    }else {
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'error';
                    this.fadeAwayMessage.message = 'Something went wrong try again!';
                    this.fadeAwayMessage.header = 'System Message';
                    }
                })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
                     this.isLoading = true;
             let data = new FormData();
              data.append('BudgetID', this.editItemId);
              data.append('FundID', this.formData.FundID);
              data.append('BudgetDate', this.formData.BudgetDate);
              data.append('BudgetDateEnd', this.formData.BudgetDateEnd);
              data.append('PurposeID', this.formData.PurposeID);
              data.append('SpecificFundSourceID', this.formData.SpecificFundSourceID);
              data.append('Amount', this.formData.Amount);
              data.append('OfficeID', this.formData.OfficeID);
              data.append('BudgetCode', this.formData.BudgetCode);
              data.append('AllotmentTypeID', this.formData.AllotmentTypeID);
              data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
              data.append('UserID', this.formData.UserID);
                         this.axiosCall(
                        '/budget/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Budget Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addBudgetDialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      checkSavings(perBudget, amount) {
        if(perBudget >= amount){
          this.amountErr2 = null;
        }
        else{
          this.amountErr2 = 'Per Budget Record must not exceed Actual Budget/Amount!';
        }
      },
    }
  }
</script>
<style scoped>
.mei-title {
  color: #777 !important;
}
</style>