<template>
<div>
  <v-card
      class="mb-5"
    >
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
             v-model="OfficeID"
            item-text="OfficeDescription"
            item-value="OfficeID"
            :items="officeList"
            hide-details
            color="header"
            label="Filter by Office"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="DeliveryUnitID"
            item-text="DeliveryUnitDescription"
            item-value="DeliveryUnitID"
            :items="deliveryUnit"
            hide-details
            color="header"
            label="Filter by Delivery Unit"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="PurposeID"
            item-text="PurposeDescription"
            item-value="PurposeID"
            :items="purpose"
            hide-details
            color="header"
            label="Filter by Program/Activity/Project"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="SpecificFundSourceID"
            item-text="SpecificFundSourceDescription"
            item-value="SpecificFundSourceID"
            :items="specificFundSource"
            hide-details
            color="header"
            label="Filter by Fund Source"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="UserID"
            item-text="name"
            item-value="UserID"
            :items="users"
            hide-details
            color="header"
            label="Filter by User"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              dense
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  dense
                  v-model="DateRange"
                  label="Date Range"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-5"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange"
                no-title
                scrollable
                range
                color="header"
                :max="new Date().toISOString().substr(0, 10)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu2.save(DateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        </v-card-text>
    </v-card>
  <v-card>
  <v-data-table
    :headers="headers"
    :items="budgetList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        class="mt-2"
      >
        <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Search"
        color="green darken-2"
        single-line
        hide-details
      ></v-text-field>
        <v-spacer></v-spacer>
        <v-autocomplete
            v-model="Status"
            item-text="description"
            item-value="id"
            :items="statuses"
            hide-details
            color="header"
            label="Status"
            style="max-width: 180px"
            class="mr-2"
          >
          </v-autocomplete>
        <vue-json-to-csv 
          :json-data="itemListForCSV"
          :labels="{ 
            BudgetCode: { title: 'Budget Code' },
            FundCode: { title: 'Fund Code' },
            OfficeDescription: { title: 'Office' },
            DeliveryUnitDescription: { title: 'Delivery Unit' },
            Amount: { title: 'Amount' },
            PerBudgetRecord: { title: 'PerBudgetRecord' },
            Remarks: { title: 'Remarks' },
            Status: { title: 'Status' },
            DateCreated: { title: 'Date Created' },
            }"
          csv-title="List of Budgets">
            <v-btn
              outlined
              color="green darken-2"
              dark
              :loading="loadingCSV"
            >
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              CSV
            </v-btn>
          </vue-json-to-csv>
            <v-btn
              color="green darken-2"
              class="ml-2"
              dark
              @click="addBudgetDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Add Budget M&E
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.PerBudgetRecord`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.PerBudgetRecord || 0) }}</span>
      </template>
      <template v-slot:[`item.BudgetUtilized`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.AmountNDBM_PO) + parseFloat(item.AmountNDBM_OBL) + parseFloat(item.AmountDBM_PR) + parseFloat(item.AmountLIB_OBL)) }}</span>
      </template>
      <template v-slot:[`item.Status`]="{ item }">
          <v-chip color="green darken-2" dark small v-if="item.SubmittedBy > 0">Submitted</v-chip>
          <v-chip color="amber darken-2" dark small v-else>Pending</v-chip>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn color="blue darken-2" class="white--text mr-2" outlined x-small @click="viewItem(item)">
          view
          </v-btn>
          <v-btn color="red darken-2" class="white--text" outlined x-small @click="print(item)">
          Print M&E
          </v-btn>
        </span>
      </template>
  </v-data-table>
   </v-card>
        <v-dialog
          v-model="addBudgetDialog"
          persistent
          max-width="550px"
          scrollable
        >
          <v-card>
            <v-card-title>
              <span>Add Budget for M & E</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
            <v-card-text style="max-height: 900px;">
              <v-form @submit.prevent ref="formRef">
              <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters>
                  <v-col cols="12" >
                    <v-autocomplete
                        v-model="formData.BudgetID"
                        item-text="BudgetCode"
                        item-value="BudgetID"
                        :rules="[formRules.required]"
                        :items="budget"
                        dense
                        outlined
                        color="header"
                        hide-details
                        @change="changeBudgetMEI()"
                        label="Budget Code">
                    </v-autocomplete>
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Fund Source: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span v-if="budgetDetails.FundCode">{{budgetDetails.FundCode + '-' + budgetDetails.SpecificFundSourceDescription}}</span>
                  </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong class="text-no-wrap">Assignee: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.Assignee}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Office: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.OfficeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Purpose: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.PurposeDescription}}
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Allotment Type: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.AllotmentTypeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Delivery Unit: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.DeliveryUnitDescription}}
                    </v-col>
                  </v-row> -->
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Actual Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <strong v-if="budgetDetails.Amount">{{formatPrice(budgetDetails.Amount) }}</strong>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Utilized Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <strong v-if="budgetDetails.Amount">{{formatPrice(budgetDetails.Amount) }}</strong> Not final
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                      cols="12"
                    >
        <v-autocomplete
            v-model="formData.QuarterID"
            item-text="description"
            item-value="id"
            :rules="[formRules.required]"
            :items="quarters"
            dense
            outlined
            color="header"
            label="Quarter">
        </v-autocomplete>

        <v-text-field
          dense
          v-model="formData.PerBudgetRecord"
          :rules="[formRules.required, formRules.noNegativeNumberAllowed, formRules.numberRequired]"
          :error-messages="amountErr2"
          @input="checkBalance(formData.PerBudgetRecord, formData.Amount)"
          type = "number"
          color="header"
          outlined
          label="Per Budget Record">
          </v-text-field>
        <v-textarea
          dense
          v-model="formData.Remarks"
          color="header"
          outlined
            label="Remarks">
          </v-textarea>
          </v-col>
              </v-row>
              </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                outlined
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn
              v-if="editItemId"
                color="blue darken-2"
                class="white--text"
                @click="update"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
                Update
              </v-btn>
              <v-btn
                color="green darken-2"
                class="white--text"
                @click="save"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    props: {
      fiscalYear: null,
    },
    data: () => ({
      searchText: "",
      searchTimeout : null,
      amountErr: null,
      amountErr2: null,
      editItemId: null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      menu1: false,
      formData: {
         BudgetID: null,
         PerBudgetRecord: null,
         Remarks: null,
      },
      addBudgetDialog: false,
      budgetList: [],
      fundList: [],
      office: [],
      deliveryunit: [],
      specificfundsourceList: [],
      purposeList: [],
      allotmentList: [],
      users: [],
      headers: [
        { text: 'Quarter', value: 'Quarter', sortable: false, align: 'center', class: "green darken-2 white--text text-caption font-weight-bold" },
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'Fund Manager', value: 'Assignee', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Amount', value: 'Amount', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Per Budget Record', value: 'PerBudgetRecord', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Amount (Utilized)', value: 'BudgetUtilized', align: 'end', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Remarks', value: 'Remarks', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Status', value: 'Status', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" }
      ],
      itemListForCSV: [],
      loadingCSV: true,
      OfficeID: 0,
      DeliveryUnitID: 0,
      PurposeID: 0,
      SpecificFundSourceID: 0,
      UserID: 0,
      officeList : [],
      deliveryUnit: [],
      purpose: [],
      specificFundSource: [],
      DateRange: null,
      menu2: false,
      budgetsForRevision: [],
      savings: 0,
      budget: [],
      budgetDetails: {},
      Status: 0,
      statuses: [
        {id: 0, description: 'All'},
        {id: 1, description: 'Pending'},
        {id: 2, description: 'Submitted'},
      ],
      quarters: [
        { id: 1, description: 'First' },
        { id: 2, description: 'Second' },
        { id: 3, description: 'Third' },
        { id: 4, description: 'Fourth' },
      ]
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
    fiscalYear: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    OfficeID: function () {
        this.initialize();
    },
    DeliveryUnitID: function () {
        this.initialize();
    },
    PurposeID: function () {
        this.initialize();
    },
    SpecificFundSourceID: function () {
        this.initialize();
    },
    UserID: function () {
        this.initialize();
    },
    DateRange: function () {
        this.initialize();
    },
    Status: {
        handler () {
         this.initialize();
        },
      },
    },
    created () {
      // this.initialize();
      this.getBudgetTypeAndOffice();
      this.getOffice();
      this.getDeliveryUnit();
      this.getPurpose();
      this.getSpecificFundSource();
      this.getBudgetsForRevision();
    },
    methods: {
      print(data) {
        window.open(this.apiUrl + "/mei/report/" + data.MEIID);
      },
      cancel() {
        this.editItemId = null;
        this.transferItemId = null;
        this.$refs.formRef.reset();
        this.addBudgetDialog = false;
      },
      initialize () {
        if(this.fiscalYear != null){
        this.loading = true;
        this.loadingCSV = true;
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
          data.append('searchText', this.searchText);
          data.append('FiscalYearID', this.fiscalYear);
          data.append("OfficeID", this.OfficeID);
          data.append("DeliveryUnitID", this.DeliveryUnitID);
          data.append("PurposeID", this.PurposeID);
          data.append("SpecificFundSourceID", this.SpecificFundSourceID);
          data.append("UserID", this.UserID);
          data.append("DateRange", this.DateRange && this.DateRange.length > 1 ? this.DateRange : '');
          data.append("Status", this.Status);
              this.axiosCall(
              '/getAllBudgetMAndE',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false;
            this.loadingCSV = false;
              this.budgetList = res.data.data.result;
              this.itemListForCSV = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
            this.getBudgetList();
          })
        }
      },
      getBudgetTypeAndOffice() {
      this.axiosCall(
              '/getOfficeAndOthersForCreateUpdate',
              'GET'
          )
          .then((res) => {
              this.office = res.data.data.office;
              this.fundList = res.data.data.fund;
              this.specificfundsourceList = res.data.data.specificfundsource;
              this.purposeList = res.data.data.purpose;
              this.allotmentList = res.data.data.allotmenttype;
              this.deliveryunit = res.data.data.deliveryunit;
              this.users = [{UserID: 0, name: 'All'}, ...res.data.data.users];
          })
		},
    changeBudgetMEI(){
      let data = this.formData.BudgetID;
      if(data){
      let details = this.budget.find(b => b.BudgetID === data);
        this.budgetDetails = details;
      }
      else{
        this.budgetDetails = {};
      }
    },
    getBudgetList() {
      this.budget = [];
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear);
      this.axiosCall(
              '/getBudgetMAndEList',
              'POST',
              data
          )
          .then((res) => {
              this.budget = res.data.data;
          })
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
          this.isLoading = true;
          let data = new FormData();
          data.append('QuarterID', this.formData.QuarterID);
          data.append('BudgetID', this.formData.BudgetID);
          data.append('PerBudgetRecord', this.formData.PerBudgetRecord);
          data.append('Remarks', this.formData.Remarks);
                      this.axiosCall(
                    '/mei/add',
                    'POST',
                    data
                )
                .then((res) => {
                    this.isLoading = false;
                    if(res.data.status) {
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'success';
                    this.fadeAwayMessage.message = 'Budget Successfully Added!';
                    this.fadeAwayMessage.header = 'System Message';
                    this.$refs.formRef.reset();
                    this.addBudgetDialog = false;
                      this.initialize();
                    }else {
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'error';
                    this.fadeAwayMessage.message = 'Something went wrong try again!';
                    this.fadeAwayMessage.header = 'System Message';
                    }
                })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
                     this.isLoading = true;
             let data = new FormData();
              data.append('BudgetID', this.editItemId);
              data.append('FundID', this.formData.FundID);
              data.append('BudgetDate', this.formData.BudgetDate);
              data.append('BudgetDateEnd', this.formData.BudgetDateEnd);
              data.append('PurposeID', this.formData.PurposeID);
              data.append('SpecificFundSourceID', this.formData.SpecificFundSourceID);
              data.append('Amount', this.formData.Amount);
              data.append('OfficeID', this.formData.OfficeID);
              data.append('BudgetCode', this.formData.BudgetCode);
              data.append('AllotmentTypeID', this.formData.AllotmentTypeID);
              data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
              data.append('UserID', this.formData.UserID);
                         this.axiosCall(
                        '/budget/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Budget Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addBudgetDialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      transfer () {
         if(this.$refs.formRef.validate() && !this.amountErr2){
              this.isLoading = true;
             let data = new FormData();
              data.append('FundID', this.formData.FundID);
              data.append('BudgetDate', this.formData.BudgetDate);
              data.append('BudgetDateEnd', this.formData.BudgetDateEnd);
              data.append('PurposeID', this.formData.PurposeID_new);
              data.append('SpecificFundSourceID', this.formData.SpecificFundSourceID);
              data.append('Amount', this.formData.Amount_new);
              data.append('OfficeID', this.formData.OfficeID_new);
              data.append('BudgetCode', this.formData.BudgetCode + '-T');
              data.append('AllotmentTypeID', this.formData.AllotmentTypeID);
              data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
              data.append('UserID', this.formData.UserID_new);
              data.append('ParentBudgetID', this.transferItemId);
                         this.axiosCall(
                        '/budget/transfer',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Budget Successfully Transferred!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addBudgetDialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      getOffice() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllOffice", "POST", data).then((res) => {
        this.officeList = res.data.data.result;
        this.officeList.push({ OfficeID: 0, OfficeDescription: "All" });
        this.officeList.sort(function (a, b) {
          return a.OfficeID - b.OfficeID;
        });
      });
    },
    getDeliveryUnit() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllDeliveryUnit", "POST", data).then((res) => {
        this.deliveryUnit = res.data.data.result;
        this.deliveryUnit.push({ DeliveryUnitID: 0, DeliveryUnitDescription: "All" });
        this.deliveryUnit.sort(function (a, b) {
          return a.DeliveryUnitID - b.DeliveryUnitID;
        });
      });
    },
    getPurpose() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllPurpose", "POST", data).then((res) => {
        this.purpose = res.data.data.result;
        this.purpose.push({ PurposeID: 0, PurposeDescription: "All" });
        this.purpose.sort(function (a, b) {
          return a.PurposeID - b.PurposeID;
        });
      });
    },
    getSpecificFundSource() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllFundsource", "POST", data).then((res) => {
        this.specificFundSource = res.data.data.result;
        this.specificFundSource.push({ SpecificFundSourceID: 0, SpecificFundSourceDescription: "All" });
        this.specificFundSource.sort(function (a, b) {
          return a.SpecificFundSourceID - b.SpecificFundSourceID;
        });
      });
    },
    getBudgetsForRevision() {
      this.axiosCall("/getBudgetsForRevision", "GET").then((res) => {
        res.data.data.result.forEach(item => {
          this.budgetsForRevision.push(...JSON.parse(item.BudgetCodes))
        });
      });
    },
    checkForTransfer(BudgetCode){
      return this.budgetsForRevision.includes(BudgetCode);
    },
    getSavings() {
        if(this.transferItemId){
          let data = new FormData();
          data.append('BudgetID', this.transferItemId);
          data.append('Amount', this.formData.Amount_new);
          this.axiosCall(
              '/getSavings',
              'POST',
              data
          )
          .then((res) => {
            this.savings = res.data.data
            
          })
        }
      },
      checkSavings(perBudget, amount) {
        if(perBudget >= amount){
          this.amountErr2 = null;
        }
        else{
          this.amountErr2 = 'Per Budget Record must not exceed Actual Budget/Amount!';
        }
      },
    }
  }
</script>